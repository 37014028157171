import { types } from "utils/types/types";
import { setItemStorareCryp } from "utils/functions/sessionStorageCrypto";

export const clientReduce = (state = {}, action) => {
  switch (action.type) {
    case types.shop:
      setItemStorareCryp("lead", action.payload, "LEADSESSIONDATA");
      return {
        idShop: action.payload.idShop,
        leadName: action.payload.leadName,
        name: action.payload.nameShop,
        phone: action.payload.phone,
        more: action.payload.more,
        creatorLead: action.payload.creatorLead,
        creatorShop: action.payload.creatorShop,
        marketplace: action.payload.marketplace,
        idLead: action.payload.idLead,
        email: action.payload.email,
        emailStore: action.payload.emailStore,
        storeStatus: action.payload.storeStatus,
        dateStartCredit: action.payload.dateStart,
        typeCredit: action.payload.typeCredit,
        creditFee: action.payload.creditFee,
        creditPrepayment: action.payload.creditPrepayment,
        proposal: action.payload.proposal,
        idDisposition: action.payload.idDisposition,
        creditIva: action.payload.creditIva,
        accessMail: action.payload.accessMail,
        storeLink: action.payload.storeLink,
        shopStatus: action.payload.shopStatus,
        leadsList: state.leadsList,
        leadsIdList: state.leadsIdList
      };
    case types.shopConcat:
    case types.setClientInfo:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
